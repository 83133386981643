import React, { Component } from "react"
import { Container, Row, Col } from "react-bootstrap"

import { useStaticQuery, graphql, Link } from "gatsby"
class Footer extends Component {
  render() {
    return <RenderFooter />
  }
}

export default Footer

function RenderFooter() {
  const AllMenuGroupsRaw = useStaticQuery(graphql`
    {
      allSilverStripeDataObject(
        filter: {
          className: {
            in: [
              "Undigital__Objects__FooterMenuGroup"
              "Undigital__Objects__GlobalConfig"
            ]
          }
        }
      ) {
        nodes {
          UndigitalFooterMenuGroup {
            title
            Menus {
              UndigitalFooterMenu {
                link
                title
                sortOrder
              }
            }
          }
          UndigitalGlobalConfig {
            facebook
            twitter
            youtube
            instagram
            googlePlus
            linkedIn
            pinterest
            experienceTitle
            experienceDetail
            adminEmail
            phone
            address
            contactEmail
          }
        }
      }
    }
  `)

  const AllMenuGroups = AllMenuGroupsRaw.allSilverStripeDataObject.nodes
  var _menuGroups = []
  var _globalConfig = null
  var i = 0
  for (; AllMenuGroups[i]; ) {
    var item = AllMenuGroups[i]
    if (i === 2) {
      item.col = 2
    } else {
      item.col = 3
    }
    if (item.UndigitalFooterMenuGroup !== null) {
      _menuGroups.push(item)
    }
    if (_globalConfig === null && item.UndigitalGlobalConfig !== null) {
      _globalConfig = item.UndigitalGlobalConfig
    }
    i++
  }
  return (
    <>
      <footer className="footer">
        <Container>
          <Row className="has-border" noGutters={true}>
            <Col sm={6} lg={3}>
              <div className="footer-item">
                <strong className="footer-title">Phone Us</strong>
                <p>
                  <span className="footer-number">{_globalConfig.phone}</span>
                </p>
              </div>
            </Col>
            <Col sm={6} lg={3}>
              <div className="footer-item">
                <strong className="footer-title">Email Us</strong>
                <p>{_globalConfig.contactEmail}</p>
              </div>
            </Col>
            <Col sm={6} lg={3}>
              <div className="footer-item">
                <strong className="footer-title">Find Us</strong>
                <p>{_globalConfig.address}</p>
              </div>
            </Col>
            <Col sm={6} lg={3}>
              <div className="footer-item">
                <div className="social-group">
                  {/* {_globalConfig.linkedIn && (
                                <a href={_globalConfig.linkedIn} target="_blank">
                                    <svg width='30' height='29.97'>
                                        <use xlinkHref='/img/icons.svg#icon-social-lkn'></use>
                                    </svg>
                                </a>
                                )} */}
                  {_globalConfig.instagram && (
                    <a href={_globalConfig.instagram} target="_blank">
                      <svg width="30" height="30">
                        <use xlinkHref="/img/icons.svg#icon-social-ins"></use>
                      </svg>
                    </a>
                  )}
                  {_globalConfig.pinterest && (
                    <a href={_globalConfig.pinterest} target="_blank">
                      <svg width="30" height="29.97">
                        <use xlinkHref="/img/icons.svg#icon-social-pin"></use>
                      </svg>
                    </a>
                  )}
                  {_globalConfig.facebook && (
                    <a href={_globalConfig.facebook} target="_blank">
                      <svg width="30" height="28.85">
                        <use xlinkHref="/img/icons.svg#icon-social-fb"></use>
                      </svg>
                    </a>
                  )}
                </div>
              </div>
            </Col>
          </Row>

          <Row className="has-border" noGutters={true}>
            {_menuGroups.map((item, index) => (
              <Col key={`menugroup-${index}`} sm={6} lg={`${item.col}`}>
                <div className="footer-item">
                  <strong className="footer-title">
                    {item.UndigitalFooterMenuGroup.title}
                  </strong>
                  <ul className="footer-item-list">
                    {item.UndigitalFooterMenuGroup.Menus.map(
                      (menuItem, index) => (
                        <li key={`menugroupmenu-${index}`}>
                          <a href={menuItem.UndigitalFooterMenu.link}>
                            {menuItem.UndigitalFooterMenu.title}
                          </a>
                        </li>
                      )
                    )}
                  </ul>
                </div>
              </Col>
            ))}
          </Row>

          <div className="footer-copyright">
            <p>©2021 Birdy Homes. All rights Reserved.</p>
            <a href="/" className="footer-logo">
              <img src="/img/footer-logo.svg" alt="footer logo" />
            </a>
          </div>
        </Container>
      </footer>
    </>
  )
}
