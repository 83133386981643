import React, { useCallback, useState, useEffect } from "react"
import Sticky from 'react-sticky-el'
import SocialLinks from "./SocialLinks"
import { isLoggedIn } from "../../../services/auth"
import { useStaticQuery, graphql } from "gatsby"
import SlideToggle from "react-slide-toggle"

function ExploreHeader(data) {
    const menuItemsRaw = useStaticQuery(graphql`
    {
        allSilverStripeDataObject(filter: {SilverStripeSiteTree: {title: {ne: null}}}, sort: {fields: SilverStripeSiteTree___sort, order: ASC}) {
            nodes {
              SilverStripeSiteTree {
                urlSegment
                title
                menuTitle
                showInMenus
                showInSearch
                sort
                parentID
                Children {
                    SilverStripeSiteTree{
                      urlSegment
                      title
                      menuTitle
                      showInMenus
                      showInSearch
                      sort
                    }
                }
              }
            }
          }
    }`);
    const menuItems = menuItemsRaw.allSilverStripeDataObject.nodes;
    var allMenus = [];
    var i = 0;
    for (; menuItems[i]; ) {
        let menuItem = menuItems[i].SilverStripeSiteTree;
        let showInMenus = menuItem.showInMenus
        let parentID = menuItem.parentID
        if(showInMenus != 0 && parentID == 0){
            allMenus.push(menuItem);
        }
        i++;
    }
    allMenus.sort((a, b) => {
        return a.sort*1 > b.sort*1 ? 1 : -1;
    });
    
    const pageData = data.data;
    
    let isClose = true
    const [isActive, setActive] = useState(isClose)
    const handleToggle = () => {
      setActive(!isClose)
    }
    
    const [logged, setLogged] = useState(false)
    useEffect(() => {
        const isLogged = isLoggedIn()
        setLogged(isLogged)
    }, []);
    return (
        <div className="explore-header-wrap">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 d-none d-lg-block">
                    <Sticky boundaryElement=".explore-header-wrap">
                        <div className="mSidebar">
                            <div className="mSidebar-inner">
                                {allMenus && (
                                <ul className="mSidebar-menu">
                                    {allMenus.map((menu, idx) => (
                                        <>
                                        {menu.Children ? (
                                            <SlideToggle key={`dropdown-menu-${idx}`} collapsed={isClose}>
                                                {({ toggle, setCollapsibleElement, toggleState }) => (
                                                  <li className="has-item">
                                                    <a href={`/${menu.urlSegment}`}>{menu.menuTitle}</a>
                                                    <div
                                                      className={`toggle-item${toggleState == "EXPANDED" ? " active" : ""}`}
                                                      onClick={e => {
                                                        toggle(e)
                                                        handleToggle()
                                                        e.preventDefault()
                                                      }}
                                                    ></div>
                                                    <ul className="menu-item" ref={setCollapsibleElement}>
                                                        {menu.Children.map((childMenu, childIdx) => (
                                                            <li key={`child-menu-${childIdx}`}>
                                                              <a href={`/${menu.urlSegment}/${childMenu.SilverStripeSiteTree.urlSegment}`}>{childMenu.SilverStripeSiteTree.menuTitle}</a>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                  </li>
                                                )}
                                              </SlideToggle>
                                        ):(
                                            <li key={`menu-${idx}`}>
                                                <a href={`/${menu.urlSegment}`}>{menu.menuTitle}</a>
                                            </li>
                                        )}
                                    </>
                                    ))}
                                </ul>
                                )}                                
                                <a href="/explore/" className={`btn btn-black btn-big${!logged ? ' d-none' : ''}`}>EXPLORE BIRDY</a>
                                {logged ? (
                                <ul className={`mSidebar-sub-menu${!logged ? ' d-none' : ''}`}>
                                    <li><a href="/account">Settings</a></li>
                                    <li><a href="/logout/">Logout</a></li>
                                </ul>
                                ):(
                                <ul className={`mSidebar-sub-menu${!logged ? ' d-none' : ''}`}>
                                    <li><a href="/login">Login</a></li>
                                    <li><a href="/register/">Register</a></li>
                                </ul>
                                )}
                                <SocialLinks/>
                            </div>
                        </div>
                    </Sticky>
                    </div>
                    <div className="col-12 col-lg-9">
                        <div className="explore-header-inner">
                            <a href={pageData.bannerLink}>
                            <img className="explore-header-inner-background d-none d-sm-none d-md-none d-lg-block" src={pageData.coverImage} alt=""/>
                            <img className="explore-header-inner-background d-none d-sm-none d-md-block d-lg-none" src={pageData.coverImageTablet} alt=""/>
                            <img className="explore-header-inner-background d-sm-block d-md-none d-lg-none" src={pageData.coverImageMobile} alt=""/>
                            <div className="explore-header-inner-content">
                                <div className="svg-wrap">
                                    {/* image here */}
                                </div>
                            </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ExploreHeader;